import React, { useState } from 'react';
import './index.sass';
import { release, SoundtrackItem, SoundtrackPlaylist, track } from '../../models/soundModels';
import { ControlsPlay } from '../AudioPlayer/controls';


interface MoreInfoProps {
  current?: release|null,
  t: SoundtrackItem|null,
  pct: number,
  paused: boolean,
  onTracks?: (tracks:SoundtrackPlaylist, index:number, action?:string) => void,
  onClose?: (n:null) => void
  onPause?: (pause:boolean) => void
}

function MoreInfo(props:MoreInfoProps) {

  const playAlbum = (r:release|undefined, index = 0, action='playnow') => {
    if (props.t && props.current && isOnAlbum(props.t.name, props.current.tracks) && props.paused) {
      if (props.onPause) props.onPause(false);
    } else if (props.t && props.current && isOnAlbum(props.t.name, props.current.tracks) && !props.paused) {
      if (props.onPause) props.onPause(true);
    } else {
      const trcks:SoundtrackPlaylist = [];
      r?.tracks.forEach((t) => {
        trcks.push({
          name: t.name,
          album: r.name,
          isrc: t.isrc,
          tag: r.name,
          lyrics: t.lyrics,
        });
        if (props.onTracks) props.onTracks(trcks, index, action);
      });
    }
  }

  const playSong = (trck:track, action='playnow') => {
    if (props.t?.name === trck.name && props.paused) {
      if (props.onPause) props.onPause(false);
    } else if (props.t?.name === trck.name && !props.paused) {
      if (props.onPause) props.onPause(true);
    } else {
      const trcks:SoundtrackPlaylist = [];
      trcks.push({
        name: trck.name,
        album: props.current?.name||'',
        isrc: props.current?.isrc||'',
        tag: props.current?.name||'',
        lyrics: trck.lyrics,
      });
      if (props.onTracks) props.onTracks(trcks, 0, action);
    }
  };

  const getDate = (d:string|undefined) => {
    if (d) {
      const dt = new Date(d);
      return Intl.DateTimeFormat('pt-BR', { dateStyle: 'short' }).format(dt)
    }
    return '';
  }

  const isOnAlbum = (n:string, trcks:Array<track>):boolean => {    
    return trcks.map(t => t.name).indexOf(n) > -1
  }

  return (
    <div className='MoreInfo scrollable'>
      <div className='releaseViewContent'>
        <div className='topper'>
          <img src={props.current?.cover} alt="" className='transition'/>
          <div className='infos'>
            <div className='title'>{props.current?.name}</div>
            <div className='date'>{getDate(props.current?.release)}</div>
            <div className='actions'>
                <ControlsPlay filled paused={props.paused} tracked={props.t && props.current && isOnAlbum(props.t.name, props.current.tracks)} onPlayPause={() => { playAlbum(props.current || undefined) }} pct={props.pct} />
                <svg className='add transition' viewBox="0 0 32 32" onClick={()=> { playAlbum(props.current || undefined, 0, 'playnext') }}>
                  <circle cx="16" cy="16" r="13"/>
                  <path d="M16 10V22" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M22 16L10 16" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <a href={props.current && props.current.tracks.length > 1 ? `audio/wav/${props.current?.name}.zip` : `audio/wav/${props.current?.name}.wav`} download>
                  <svg className='download transition' viewBox="0 0 32 32">
                    <circle cx="16" cy="16" r="13"/>
                    <path d="M17 10C17 9.44772 16.5523 9 16 9C15.4477 9 15 9.44772 15 10H17ZM15.2929 23.7071C15.6834 24.0976 16.3166 24.0976 16.7071 23.7071L23.0711 17.3431C23.4616 16.9526 23.4616 16.3195 23.0711 15.9289C22.6805 15.5384 22.0474 15.5384 21.6569 15.9289L16 21.5858L10.3431 15.9289C9.95262 15.5384 9.31946 15.5384 8.92893 15.9289C8.53841 16.3195 8.53841 16.9526 8.92893 17.3431L15.2929 23.7071ZM15 10V23H17V10H15Z" fill="black"/>
                  </svg>
                </a>
                {
                  props.current?.tracks && props.current?.tracks.length === 1 &&
                  <a href={`https://www.youtube.com/watch?v=${props.current?.tracks[0].video}`} target='blank'>
                    <svg className={`video transition ${!props.current?.tracks[0].video ? 'not' : ''}`} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M47.5219 14.4001C47.5219 14.4001 47.0531 11.0907 45.6094 9.63759C43.7812 7.72509 41.7375 7.71571 40.8 7.60321C34.0875 7.11571 24.0094 7.11572 24.0094 7.11572H23.9906C23.9906 7.11572 13.9125 7.11571 7.2 7.60321C6.2625 7.71571 4.21875 7.72509 2.39062 9.63759C0.946875 11.0907 0.4875 14.4001 0.4875 14.4001C0.4875 14.4001 0 18.2907 0 22.172V25.8095C0 29.6907 0.478125 33.5813 0.478125 33.5813C0.478125 33.5813 0.946875 36.8907 2.38125 38.3438C4.20937 40.2563 6.60938 40.1907 7.67813 40.397C11.5219 40.7626 24 40.8751 24 40.8751C24 40.8751 34.0875 40.8563 40.8 40.3782C41.7375 40.2657 43.7812 40.2563 45.6094 38.3438C47.0531 36.8907 47.5219 33.5813 47.5219 33.5813C47.5219 33.5813 48 29.7001 48 25.8095V22.172C48 18.2907 47.5219 14.4001 47.5219 14.4001ZM19.0406 30.2251V16.7345L32.0062 23.5032L19.0406 30.2251Z"/>
                    </svg>
                  </a>
                }
            </div>
          </div>
        </div>
        {
          props.current?.tracks && props.current?.tracks.length > 1 &&
            <div className='tracks'>
              {
                props.current?.tracks.map( (t, i) => 
                  <div className='track' key={`track${i}`}>
                    <div className='infos'>
                      <div className='number'>{i+1}</div>
                      <div className='name ellipsis'>{t.name}</div>
                    </div>
                    <div className='controls'>
                      <ControlsPlay filled paused={props.paused} tracked={props.t?.name === t.name} onPlayPause={() => { playSong(t) }} pct={props.pct} />
                      <svg className='add transition' viewBox="0 0 32 32" onClick={()=> { playSong(t, 'playnext') }}>
                        <circle cx="16" cy="16" r="13"/>
                        <path d="M16 10V22" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M22 16L10 16" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                      <a href={`audio/wav/${t.name}.wav`} download>
                        <svg className='download transition' viewBox="0 0 32 32">
                          <circle cx="16" cy="16" r="13"/>
                          <path d="M17 10C17 9.44772 16.5523 9 16 9C15.4477 9 15 9.44772 15 10H17ZM15.2929 23.7071C15.6834 24.0976 16.3166 24.0976 16.7071 23.7071L23.0711 17.3431C23.4616 16.9526 23.4616 16.3195 23.0711 15.9289C22.6805 15.5384 22.0474 15.5384 21.6569 15.9289L16 21.5858L10.3431 15.9289C9.95262 15.5384 9.31946 15.5384 8.92893 15.9289C8.53841 16.3195 8.53841 16.9526 8.92893 17.3431L15.2929 23.7071ZM15 10V23H17V10H15Z" fill="black"/>
                        </svg>
                      </a>
                      <a href={`https://www.youtube.com/watch?v=${t.video}`} target='blank'>
                        <svg className={`video transition ${!t.video ? 'not' : ''}`} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M47.5219 14.4001C47.5219 14.4001 47.0531 11.0907 45.6094 9.63759C43.7812 7.72509 41.7375 7.71571 40.8 7.60321C34.0875 7.11571 24.0094 7.11572 24.0094 7.11572H23.9906C23.9906 7.11572 13.9125 7.11571 7.2 7.60321C6.2625 7.71571 4.21875 7.72509 2.39062 9.63759C0.946875 11.0907 0.4875 14.4001 0.4875 14.4001C0.4875 14.4001 0 18.2907 0 22.172V25.8095C0 29.6907 0.478125 33.5813 0.478125 33.5813C0.478125 33.5813 0.946875 36.8907 2.38125 38.3438C4.20937 40.2563 6.60938 40.1907 7.67813 40.397C11.5219 40.7626 24 40.8751 24 40.8751C24 40.8751 34.0875 40.8563 40.8 40.3782C41.7375 40.2657 43.7812 40.2563 45.6094 38.3438C47.0531 36.8907 47.5219 33.5813 47.5219 33.5813C47.5219 33.5813 48 29.7001 48 25.8095V22.172C48 18.2907 47.5219 14.4001 47.5219 14.4001ZM19.0406 30.2251V16.7345L32.0062 23.5032L19.0406 30.2251Z"/>
                        </svg>
                      </a>
                    </div>
                  </div>
                )
              }
            </div>
        }
        <div className='wrapper'>
          {
            props.current?.tracks.map( (t, i) => 
            <div key={`lyric${i}`}>
              {props.current &&props.current?.tracks.length > 1  &&
                 <div className='title' key={`title${i}`}>{t.name}</div>
              }
              {t.lyrics}
            </div>
              
            )
          }
        </div>
      </div>
    </div>
  );
}

export default MoreInfo;
